import { IStrategy } from './IStrategy'

export class AdapterBase<Input, Output> {
  private strategies: Record<string, IStrategy<Input, Output>> = {}

  set(strategy: new () => IStrategy<Input, Output>) {
    this.strategies[strategy.name] = new strategy()
  }

  public convert<O extends Output, I extends Input>(
    stategy: new () => IStrategy<I, O>,
    response: I
  ): O {
    const name = stategy.name
    if (!this.strategies[name]) {
      console.error('No strategy found for ', name)
      throw new Error('Require a strategy ')
    }
    return this.strategies[name].convert(response) as O
    // .apply(this.strategies[name], [
    //   response
    // ])
  }
}
